<template>
  <div>
    <CategoryList></CategoryList>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CategoryList from "@/view/pages/category/components/CategoryList";

export default {
  name: "CategoryListings",
  components: {
    CategoryList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "CategoryListings" }]);
  },
};
</script>