<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <div class="d-flex flex-row align-items-center">
        <h2 class="font-weight-bold my-2 mr-5">
          Kategoriler
        </h2>
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" style="width: 1px; height:22px;"></div>
        <div class="d-flex align-items-center">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">{{ items.length }} Adet</span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
      <router-link
        :to="{ name: 'create-category'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="svg-icon">
            <inline-svg src="media/svg/icons/Code/Plus.svg" />
          </span>
          <span class="d-none d-md-inline">Kategori Ekle</span>
        </a>
      </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6" v-for="item in items" :key="item.id">
        <!--begin::Card-->
        <div class="card card-custom gutter-b card-stretch">
          <!--begin::Body-->
          <div class="card-body pt-4">
            <!--begin::User-->
            <div class="d-flex align-items-end mb-7">
              <!--begin::Pic-->
              <div class="d-flex align-items-center">
                <!--begin::Pic-->
                <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                  <div class="symbol bg-gray-100 symbol-lg-75 p-2">
                    <img :src="item.icon" :alt="item.title">
                  </div>
                </div>
                <!--end::Pic-->
                <!--begin::Title-->
                <div class="d-flex flex-column">
                  <router-link :to="{ name: 'show-category', params: { id: item.id } }" v-slot="{ href }">
                    <a :href="href" class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{{ item.title }}</a>
                  </router-link>
                </div>
                <!--end::Title-->
              </div>
              <!--end::Title-->
            </div>
            <!--end::User-->
            <!--begin::Info-->
            <div class="mb-7">
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-dark-75 font-weight-bolder mr-2">Alt kategori:</span>
                <span class="text-muted">{{ item.numberOfSubCategory }} Adet</span>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-dark-75 font-weight-bolder mr-2">Durum:</span>
                <span class="text-success font-weight-bold" v-if="item.status === 1">Yayında</span>
                <span class="text-warning font-weight-bold" v-if="item.status === 2">Yayında değil</span>
              </div>
            </div>
            <!--end::Info-->
            <router-link :to="{ name: 'show-category', params: { id: item.id } }" v-slot="{ href }">
              <a :href="href" class="btn btn-block btn-sm btn-light btn-hover-primary font-weight-bolder text-uppercase py-4">Görüntüle</a>
            </router-link>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Card-->
      </div>
    </div>
  </div>
  
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "CategoryList",
  data() {
    return {
      items: []
    }
  },
  methods: {
    getCategories() {
      ApiService.get('category/getListings.req.php')
      .then(({ data }) => {
        this.items = data.categories;
      })
      .catch(({ response }) => {
        this.items = [];
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      })
    }
  },
  mounted() {
    this.getCategories();
  }
}
</script>

<style>

</style>